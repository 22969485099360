<template>
	<component :is="Chart" :chartData="data" :options="options" />
</template>

<script>
import { getSituacionIcon } from "@/utils/index.js";
import colors from "vuetify/lib/util/colors";
import Chart from '@/components/charts/index.js'

export default {
	// extends: Doughnut,
	props: {
		dataKey: String,
		type: {
			type: String,
			default: 'BarChart'
		},
		label: {
			type: String,
			default: null
		},
	},
	data() {
		return {
			data: null,
		};
	},
	computed: {
		Chart() {
			return Chart( this.type )
		},
		options() {
			return {
				responsive: true,
				maintainAspectRatio: false,
				legend: {
					display: true,
					position: "bottom",
					labels: {
						usePointStyle: true,
					},
				},
				title: {
					display: false,
				},
				scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }]
    }
			};
		},
	},
	async mounted() {

		let { data: datos } = await axios({
			method: "GET",
			url: `${process.env.VUE_APP_API_URL}/resumen/${this.dataKey}`,
		});

		// const totalEstudios = datos.reduce((t, a) => t + Number(a.numero), 0);

		const getColor = (label) => {
			let col = getSituacionIcon(label).color;
			let finalColor = col;
			try {
				if (!/#/g.test(col))
					finalColor =
						this.$vuetify.theme.themes.light[col] || colors[col]["base"];
			} catch (e) {
				finalColor = col;
			}
			return finalColor.slice(0, 7);
		};

		let results = {
			labels: [],
			datasets: [
				{
					label: '',
					data: [],
					hoverBackgroundColor: [],
					backgroundColor: [],
				},
			],
		};

		datos.forEach(({ numero, key }, index) => {
			let color = getColor(key);
			results.labels[index] = key;
			results.datasets[0].label = this.label;
			results.datasets[0].data[index] = numero;
			results.datasets[0].hoverBackgroundColor[index] = color;
			results.datasets[0].backgroundColor[index] = color + "a0";
		});

		this.data = results;
		// this.renderChart(this.data.datos, this.options);
		this.$emit("loaded");
	},
};
</script>

<style>
</style>